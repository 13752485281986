import { combineReducers } from 'redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';

import userReducer from './User/UserSlice';
import timerReducer from './Timer/TimerSlice';
import { api } from '../services/api';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

let middlewares = [routerMiddleware, api.middleware];

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  middlewares = middlewares.concat([logger]);
}

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    auth: userReducer,
    timer: timerReducer,
    [api.reducerPath]: api.reducer,
    // more reducers
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

export const history = createReduxHistory(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
